var globalFunc = {
  init: function() {
    globalFunc.resize();
  },
  get_biggest: function(elements) {
    //get all elements with class and get the biggest box
    var biggest_height = 0;
    for (var i = 0; i < elements.length; i++) {
      var element_height = $(elements[i]).outerHeight();
      //compare the height, if bigger, assign to variable
      if (element_height > biggest_height) biggest_height = element_height;
    }
    return biggest_height;
  },
  resize: function() {
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();

    // STICKY FOOTER
    var headerHeight = $("header").outerHeight();
    var footerHeight = $("footer").outerHeight();
    var footerTop = footerHeight * -1;
    $("footer").css({ marginTop: footerTop });
    $("#main-wrapper").css({ paddingBottom: footerHeight });

    // for vertically middle content
    $(".bp-middle").each(function() {
      var bpMiddleHeight = ($(this).outerHeight() / 2) * -1;
      $(this).css({ marginTop: bpMiddleHeight });
    });

    // for equalizer
    $(".equal-height").css({ minHeight: 0 });
    var ClassName = globalFunc.get_biggest($(".equal-height"));
    $(".equal-height").css({ minHeight: ClassName });
  },
  touch: function(){
		if (Modernizr.touch) {
      $('html').addClass('bp-touch');
		}
	},
  customSelect: function() {
    //custom select
    $("select.custom-select").each(function() {
      $(this).wrap('<div class="custom-select-wrapper" />');
      $(this).before('<div class="custom-select-display" />');
      $(this).change(function() {
        var country = $(".footer-country");
        var selectedOpt = $(this).find("option:selected");
        var defValue = $(selectedOpt).attr("value");
        var flag;
        country.each(function() {
          if ($(this).data().country === selectedOpt.val()) {
            flag = $(this);
          }
        });
        var selectedFlag = $(flag).clone();
        $(this)
          .siblings(".custom-select-display")
          // .text($(selectedOpt).text());
          .html('<p class="m-0 w-100 text-center">'+ $(selectedOpt).text() +'</p>')
        $(this)
          .siblings()
          .prepend($(selectedFlag))
        if (defValue == "default") {
          $(this)
            .siblings(".custom-select-display")
            .addClass("default");
        } else {
          $(this)
            .siblings(".custom-select-display")
            .removeClass("default");
        }
      });
      $(this).keyup(function() {
        var selectedOpt = $(this).find("option:selected");
        var defValue = $(selectedOpt).attr("value");
        $(this)
          .siblings(".custom-select-display")
          .text($(selectedOpt).text());
        if (defValue == "default") {
          $(this)
            .siblings(".custom-select-display")
            .addClass("default");
        } else {
          $(this)
            .siblings(".custom-select-display")
            .removeClass("default");
        }
      });
      $(this).change();
    });
  },

  videoModal: function() {
    $('.media-page--video-item').each(function(i) {
      $(this).on('click', function(){
        $('.media-page--video-item source').each(function(idx) {
          if(i === idx) {
            var sourceVideo = $(this)[0].attributes[0].value
            var modalVideo = $('.modal--video');
            modalVideo.attr('src', sourceVideo)
          }
        })
      })
    })
    $('#media-modal-video').on('hidden.bs.modal', function() {
      $('.modal--video').attr('src', '');
    })
  },

  mediaCarousel: function() {
    $("#athletes").slick({
      slidesToShow: 5,
      lazyLoad: 'ondemand',
      arrows: true,
      // centerMode: true,
      
      // prevArrow:
      //   '<button class="media-page__arrow media-page__arrow--left"><img src="./dist/images/media/left.png" alt=""></button>',
      // nextArrow:
      //   '<button class="media-page__arrow media-page__arrow--right"><img src="./dist/images/media/right.png" alt=""></button>'
      nextArrow:
        '<button class="slick-next slick-arrow media-arrows media-arrows--next"><img src="'+baseUrl+'/assets/dist/images/media/right.svg" alt=""></button>',
      prevArrow:
        '<button class="slick-prev slick-arrow media-arrows media-arrows--prev"><img src="'+baseUrl+'/assets/dist/images/media/left.svg" alt=""></button>',
        responsive : [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
            }
          }
        ]
    });

    $('#environments').slick({
      slidesToShow: 3,
      lazyLoad: 'ondemand',
      arrows: true,
      centerMode: true,
      nextArrow:
        '<button class="slick-next slick-arrow media-arrows media-arrows--next"><img src="'+baseUrl+'/assets/dist/images/media/right.svg" alt=""></button>',
      prevArrow:
        '<button class="slick-prev slick-arrow media-arrows media-arrows--prev"><img src="'+baseUrl+'/assets/dist/images/media/left.svg" alt=""></button>',
        responsive : [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              centerMode: false
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              centerMode: false
            }
          }
        ]
    })

    $('#mascots').slick({
      slidesToShow: 5,
      lazyLoad: 'ondemand',
      arrows: true,
      centerMode: true,
      nextArrow:
        '<button class="slick-next slick-arrow media-arrows media-arrows--next"><img src="'+baseUrl+'/assets/dist/images/media/right.svg" alt=""></button>',
      prevArrow:
        '<button class="slick-prev slick-arrow media-arrows media-arrows--prev"><img src="'+baseUrl+'/assets/dist/images/media/left.svg" alt=""></button>',
        responsive : [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              centerMode:false
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
              centerMode: false
            }
          }
        ]
        
    })

    // $('#videos').slick({
    //   slidesToShow: 1,
    //   centerMode: true,
    //   lazyLoad: 'ondemand',
    //   arrows: true,
    // });
  },

  gamePlayControls: function() {
    var device = $('.controls__device-container');
    $(".controls__details-cta-btn").each(function(i) {
      $(this).click(function() {
        if (!$(this).hasClass("active")) {
          $(".controls__details-cta-btn").each(function() {
            $(this).removeClass("active");
          });
          $(this).addClass("active");
        }
        device.each(function(indx) {
          if(indx === i) {
            $('.controls__device-container').each(function(){
              $(this).removeClass("active");
            })
            $(this).addClass("active");
          }
        })
      });
    });

    $(".controls__device-controller").each(function(i) {
      $(this).click(function() {
        if (!$(this).hasClass("active")) {
          $(".controls__device-controller").each(function() {
            $(this).removeClass("active");
          });
          $(".controls__device-content").each(function() {
            $(this).removeClass("selected");
          });
          $(".controls__device-content")[i].classList.add("selected");
          $(this).addClass("active");
          $("#current-controller").text(i + 1);
        }
      });
    });
  },

  pabControls: function() {
    $(".pab__details-cta-btn").each(function(i) {
      $(this).click(function() {
        if (!$(this).hasClass("active")) {
          $(".pab__details-cta-btn").each(function() {
            $(this).removeClass("active");
          });
          $(this).addClass("active");
        }
      });
    });

    $(".pab__details-cta--batter").click(function() {
      $(".statistics__batter").fadeIn();
      $(".statistics__pitcher").hide();
    });

    $(".pab__details-cta--pitcher").click(function() {
      $(".statistics__pitcher").fadeIn();
      $(".statistics__batter").hide();
    });

    if ($(window).width() > 1200) {
      $(".statistics__batter").removeAttr("style");
      $(".statistics__pitcher").removeAttr("style");
    }
  },

  cosmeticsSlick: function() {
    // fix on big screen
    var footerHeight2 = $("footer").outerHeight();
    var cosmeticsHeight = "calc(100vh " + "-" + " " + footerHeight2 + "px)";

    // for grayscale effect on IE
    $(".cosmetics").css("min-height", cosmeticsHeight);
    $(".cosmetics__slider--main")
      .on("init", function(event, slick) {
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
          $(".slick-current img").addClass("grayscale");
        } else {
          $(".slick-current img").removeClass("grayscale");
        }
      })
      .on("afterChange", function(event, slick, currentSlide, nextSlide) {
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
          $(".slick-slide img").removeClass("grayscale");
          $(".slick-current img").addClass("grayscale");
        } else {
          $(".slick-slide img").addClass("grayscale");
          $(".slick-current img").removeClass("grayscale");
        }
      });

    // for slider
    $(".cosmetics__slider--main").slick({
      dots: false,
      infinite: true,
      slidesToShow: 5,
      centerMode: true,
      centerPadding: "150px",
      focusOnSelect: true,
      // appendArrows: ".slide-controlss",
      prevArrow: ".slide-arrow.arrow-prev",
      nextArrow: ".slide-arrow.arrow-next",
      // asNavFor: ".cosmetics__slider--label",
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3,
            centerPadding: "100px"
          }
        },
        {
          breakpoint: 577,
          settings: {
            slidesToShow: 1,
            centerPadding: "150px"
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerPadding: "100px"
          }
        },
        {
          breakpoint: 321,
          settings: {
            slidesToShow: 1,
            centerPadding: "80px"
          }
        }
      ]
    });
    // $(".cosmetics__slider--colors").slick({
    //   dots: false,
    //   infinite: true,
    //   slidesToShow: 1,
    //   focusOnSelect: true,
    //   prevArrow: false,
    //   nextArrow: false
    // });
    // $(".cosmetics__slider--variants").slick({
    //   dots: false,
    //   infinite: true,
    //   slidesToShow: 1,
    //   focusOnSelect: true,
    //   prevArrow: false,
    //   nextArrow: false
    // });
    // $(".cosmetics__slider--label").slick({
    //   dots: false,
    //   infinite: true,
    //   slidesToShow: 1,
    //   focusOnSelect: true,
    //   prevArrow: false,
    //   nextArrow: false,
    //   asNavFor: ".cosmetics__slider--main"
    // });

    //color selection
    $(".color-selection li").on("click", function() {
      var color = $(this).css("background-color");
      $(this)
        .addClass("selected")
        .siblings()
        .removeClass("selected");
      if ($(".color-selection li").hasClass("selected")) {
        $(".variant-selection li.selected").css("border", "3px solid " + color);
      }
    });
    // variant selection
    var color3;
    $(".variant-selection .selected").css("border", "3px solid " + color3);
    $(".variant-selection li").on("click", function() {
      color3 = $(".color-selection .selected").css("background-color");
      $(this)
        .addClass("selected")
        .css("border", "3px solid " + color3)
        .siblings()
        .removeClass("selected")
        .css("border", "3px solid transparent");
    });
  },

  pvpSlick: function() {
      $(".realtime__live-competition--section .img-wrapper").each(function() {
        var datapointer = $(this).attr("data-pointer");
        $(this).on("mouseenter", function() {
          $(this)
            .addClass("active")
            .parent()
            .siblings()
            .find(".img-wrapper")
            .removeClass("active");
          $(datapointer)
            .addClass("active")
            .siblings()
            .removeClass("active");
        });
      });

      $(".realtime__live-competition--slider").slick({
        dots: false,
        centerMode: true,
        centerPadding: "100px",
        slidesToShow: 1,
        infinite: true,
        focusOnSelect: true,
        prevArrow: '<i class="fas fa-chevron-left slick-prev slick-arrow"></i>',
        nextArrow:
          '<i class="fas fa-chevron-right slick-next slick-arrow"></i>',
        asNavFor: ".realtime__live-competition--slider-details",
        responsive: [
          {
            breakpoint: 375,
            settings: {
              centerPadding: "50px"
            }
          }
        ]
      });

      $(".realtime__live-competition--slider-details").slick({
        dots: false,
        slidesToShow: 1,
        prevArrow: false,
        nextArrow: false,
        asNavFor: ".realtime__live-competition--slider"
      });
  },

  limitText: function() {
    $(".media-text").each(function() {
      if ($(this).text().length > 100) {
        $(this).text(
          $(this)
            .text()
            .substring(0, 100) + "..."
        );
      }
      if ($(window).width() <= 425) {
        if ($(this).text().length > 70) {
          $(this).text(
            $(this)
              .text()
              .substring(0, 70) + "..."
          );
        }
      }
    });
  },

  burgerMenu: function() {
    $(".burger-menu").click(function() {
      $(this).toggleClass("open");
      $(".header__box-nav").toggleClass("open");
      $('body').toggleClass("overflow");
    });
  },

  //Menu Animation on scroll
  menuAnimate: function() {
    $(window).on("DOMMouseScroll  mousewheel", function(event) {
      if (event.originalEvent.detail > 0 || event.originalEvent.wheelDelta < 0) {
        $("header").addClass("down");
      } else {
        $("header").removeClass("down");
      }
      return false;
    });
  },

  carousel3d: function() {
    if ($(window).width() >= 1201) {
      $("#carousel-devices").Cloud9Carousel({
        autoPlay: true,
        yOrigin: 0,
        yRadius: 70,
        xRadius: 225,
        farScale: 0.8,
        bringToFront: true,
        // mouseWheel: true,
        frontItemClass: "current-slide"
      });
    } else if ($(window).width() >= 992) {
      $("#carousel-devices").Cloud9Carousel({
        autoPlay: true,
        yOrigin: 0,
        yRadius: 70,
        xRadius: 180,
        farScale: 0.8,
        bringToFront: true,
        frontItemClass: "current-slide"
      });
    } else if ($(window).width() >= 768) {
      $("#carousel-devices").Cloud9Carousel({
        autoPlay: true,
        yOrigin: 0,
        yRadius: 100,
        xRadius: 180,
        farScale: 0.8,
        bringToFront: true,
        frontItemClass: "current-slide"
      });
    } else if ($(window).width() >= 540) {
      $("#carousel-devices").Cloud9Carousel({
        autoPlay: true,
        yOrigin: 0,
        yRadius: 70,
        xRadius: 150,
        farScale: 0.8,
        bringToFront: true,
        frontItemClass: "current-slide"
      });
    } else {
      $("#carousel-devices").Cloud9Carousel({
        autoPlay: true,
        yOrigin: 0,
        yRadius: 40,
        xRadius: 90,
        farScale: 0.8,
        bringToFront: true,
        frontItemClass: "current-slide"
      });
    }
  },

  carousel3dResponsive: function() {
    $("#carousel-devices").data("carousel");
    // .deactivate();
    var clone = $("#carousel-devices").clone();
    $("#carousel-devices").remove();
    $(".main-scene").append(clone);
    globalFunc.carousel3d();
  },

  filterSelect: function() {
    $(".filter__select__options").each(function() {
      $(this).change(function() {
        var selectedOption = $(this).find("option:selected");

        $(this)
          .siblings(".filter__select__display")
          .find("p span")
          .text(selectedOption.text());
      });
    });
  },

  filterOrder: function() {
    $(".filter__select__btn").click(function() {
      $(this).toggleClass("ascending descending");
    });
  },

  showListTable: function() {
    $(".list-table__block").on("click", function(event) {
      if ($(window).width() <= 768) {
        // $(this).siblings('.list-table__details').slideDown(150);
        // $(this).parents('li').siblings().find('.list-table__details').slideUp(150);

        var targetList = $(this).siblings(".list-table__details");

        if (targetList.hasClass("show-list")) {
          targetList.removeClass("show-list");
        } else {
          targetList.addClass("show-list");
          $(this)
            .parents("li")
            .siblings()
            .find(".list-table__details")
            .removeClass("show-list");
        }
      }
    });

    // if($(window).width()>768){
    //   $('.list-table__details').removeAttr("style");
    // }
  },
  introAnimations: function() {
    $(".leaderboards-wrapper__list").appear(function() {
      var leaderBoardFilter = $(
        ".leaderboards-wrapper__filter .filter__select"
      );
      var leaderBoardList = $(".leaderboards-wrapper__list .lists-ul li");

      TweenMax.staggerFrom(
        leaderBoardFilter,
        0.25,
        { ease: Power2.easeOut, opacity: 0, x: 50 },
        0.15
      );
      TweenMax.staggerFrom(
        leaderBoardList,
        0.35,
        { ease: Power3.easeOut, opacity: 0, rotationX: 90, y: 30, delay: 0.15 },
        0.15
      );
    });

    $(".anim-intro").appear(function() {
      $(this).addClass("animated");
    });
  },

  strategy: function() {
    $(".bases").each(function() {
      $(this).hover(function() {
        var count = $(this).attr("data-count");
        $(".sd").hide();
        $(".sd__" + count).show();
      });
    });
  },
  generalAnimation: function() {
    if($('html').hasClass('no-touch')){
      $(".fadeUp").appear(function() {
        var elem = $(this);
        var el = elem.find("> *");
        setTimeout(function() {
          TweenMax.set($(elem), { opacity: 1 });
          TweenMax.staggerFrom(
            $(el),
            0.5,
            { opacity: 0, y: "50px", delay: 0.5, ease: Sine.easeOut },
            0.2
          );
        }, 100);
      });
      $(".fadeUpSelf").appear(function() {
        var elem = $(this);
        setTimeout(function() {
          TweenMax.set($(elem), { opacity: 1 });
          TweenMax.staggerFrom(
            $(elem),
            0.5,
            { opacity: 0, y: "50px", delay: 0.5, ease: Sine.easeOut },
            0.2
          );
        }, 100);
      });
      $(".fadeIn").appear(function() {
        var elem = $(this);
        var el = elem.find("> *");
        setTimeout(function() {
          TweenMax.set($(elem), { opacity: 1 });
          TweenMax.staggerFrom(
            $(el),
            0.5,
            { opacity: 0, delay: 0.5, ease: Sine.easeOut },
            0.2
          );
        }, 100);
      });
      $(".fadeInSelf").appear(function() {
        var elem = $(this);
        setTimeout(function() {
          TweenMax.set($(elem), { opacity: 1 });
          TweenMax.staggerFrom(
            $(elem),
            1,
            { opacity: 0, delay: 1, ease: Sine.easeOut },
            0.2
          );
        }, 100);
      });
      $(".fadeInRight").appear(function() {
        var elem = $(this);
        var el = elem.find("> *");
        setTimeout(function() {
          TweenMax.set($(elem), { opacity: 1 });
          TweenMax.staggerFrom(
            $(el),
            0.5,
            { opacity: 0, x: "-100px", delay: 1.5, ease: Sine.easeOut },
            0.2
          );
        }, 100);
      });
      $(".popOut").appear(function() {
        var elem = $(this);
        var el = elem.find("> *");
        setTimeout(function() {
          TweenMax.set($(elem), { opacity: 1, scale: 1 });
          TweenMax.staggerFrom(
            $(el),
            0.5,
            { opacity: 0, scale: 0, delay: 0.5, ease: Back.easeOut },
            0.2
          );
        }, 100);
      });
      $(".ballBounce").appear(function() {
        var elem = $(this);
        var el = elem.find("> *");
        setTimeout(function() {
          TweenMax.set($(elem), { opacity: 1 });
          TweenMax.staggerFrom(
            $(el),
            0.5,
            {
              opacity: 0,
              y: "-100px",
              delay: 0.5,
              ease: Bounce.easeOut
            },
            0.2
          );
        }, 100);
      });
      $(".slash").appear(function() {
        $(this).addClass("slashed");
      });
    }
  },
  cubeButton: function() {
    $(".cube-button").click(function(e) {
      var container = $(".teams__char");
      var _this = $(this);
      container.removeClass("flipped");
      $(this)
        .prev(".teams__char")
        .addClass("flipped");
      setTimeout(function() {
        _this
          .prev(".teams__char")
          .find(".charCanvas")
          .css("z-index", 4);

        _this
          .prev(".teams__char")
          .find(".canvas-holder")
          .css("z-index", 4);
      }, 500);
    });

    $(document).mouseup(function(e) {
      var container = $(".teams__char");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.removeClass("flipped");
        $(".charCanvas, .canvas-holder").css("z-index", 1);
      }
    });
  },

  parallaxImage: function() {
    //custom parallax scroll
    function runParallax() {
      var winTop = $(window).scrollTop();
      var parSpeed = winTop * 0.35;
      var parSpeed2 = winTop;

      $(".parallax-banner").each(function() {
        $(this).css({
          "background-position-y": parSpeed + "px"
        });
      });

      // $('.header-banner .img-container').css({
      // 	"top" : parSpeed+"px",
      // });
      // $('.header-banner .h-b-text').css({
      // 	"top" : 50 + parSpeed2+"%",
      // });
    }
    $(window).on("scroll", runParallax);
    runParallax();
  },

  datePicker: function() {
    $("#datepicker").datepicker({
      changeMonth: true,
      changeYear: true,
      yearRange: "1920: 2099"
    });
  },

  signupUi: function() {
    $("select#country").each(function() {
      $(this).on("change", function(e) {
        var hasVal = $(this).val();
        if (hasVal != "") {
          $(this)
            .next()
            .addClass("active");
        } else {
          $(this)
            .next()
            .removeClass("active");
        }
      });
    });
    $(".signup__control")
      .each(function() {
        var hasVal = $(this).val();
        if (hasVal != "") {
          $(this)
            .next()
            .addClass("active");
        } else {
          $(this)
            .next()
            .removeClass("active");
        }
      })
      .on("change", function() {
        var hasVal = $(this).val();
        if (hasVal != "") {
          $(this)
            .next()
            .addClass("active");
        } else {
          $(this)
            .next()
            .removeClass("active");
        }
      });
  },

  cookieBanner: function () {
      $('body').cookieBanner({
          language: typeof language !== 'undefined' ? language : 'en',
          url: typeof cookiePolicyPage !== 'undefined' ? cookiePolicyPage : '#',
          texts: {
              "en": {
                  "button": "Got it!",
                  "line1": "Gameloft uses <a href='#' data-def-url>cookies</a> to ensure that you get the best experience on our websites.",
                  "line2": "For more information, please consult our <a href='#' data-cb-url>Cookies Policy</a>. By continuing to use this site, you agree to accept cookies",
                  "line3": "",
              }
          }
      });
  },
  videoSizing: function(){
    $('.home__video-banner').each(function(){
      var self = $(this);
      var parent = self.parent();
      var windowHeight = $(window).height();
      var videoHeight = parent.width() * (9/16);
      var videoWidth = parent.width() * (16/9);

      if ( windowHeight > videoHeight ) {
        self.css({
          minWidth: videoWidth + 'px',
        });
      }
        else {
          self.css({
            minHeight: videoHeight + 'px',
          });
        }
      });
      $('.schemes__screen-video').each(function(){
        var self = $(this);
        var ipadVideoHeight = $('.schemes__screen-video').width() * (16/9);
        self.css({
          minWidth: ipadVideoHeight + 'px',
        });
      });
  },
  videos: function(){
      $('#video-banner').parent().addClass('show');
      $('.shemes__ipad').appear(function(){
        $('#video-banner2').parent().addClass('show');
      });
  },

};

$(window).resize(function() {
  globalFunc.init();
  // globalFunc.menuAnimate();
  globalFunc.limitText();
  globalFunc.showListTable();
  globalFunc.pabControls();
  globalFunc.carousel3dResponsive();
  globalFunc.videoSizing();
});

$(document).ready(function() {
  globalFunc.touch();
  globalFunc.init();
  globalFunc.parallaxImage();
  globalFunc.customSelect();
  globalFunc.gamePlayControls();
  globalFunc.cosmeticsSlick();
  globalFunc.pvpSlick();
  globalFunc.limitText();
  globalFunc.burgerMenu();
  // globalFunc.menuAnimate();
  globalFunc.filterSelect();
  globalFunc.filterOrder();
  globalFunc.introAnimations();
  globalFunc.showListTable();
  globalFunc.datePicker();
  globalFunc.signupUi();
  globalFunc.strategy();
  globalFunc.cubeButton();
  globalFunc.pabControls();
  globalFunc.mediaCarousel();
  globalFunc.carousel3d();
  globalFunc.generalAnimation();
  globalFunc.cookieBanner();
  globalFunc.videoModal();
});

$(window).on("load", function() {
  globalFunc.init();
  globalFunc.videoSizing();
  globalFunc.videos();
});

// var lastScrollTop = 0;
// $(window).scroll(function (event) {
//   var st = $(this).scrollTop();
//   if (st > lastScrollTop) {
//     // downscroll code
//     $("header").addClass("down");
//   } else {
//     $("header").removeClass("down");
//   }
//   lastScrollTop = st;
// });


$(function() {
  if($('html').hasClass('no-touch')){
    $("body").niceScroll({
      scrollspeed: 100,
    });
  }
});

// preloader once done
Pace.on("done", function() {
  // totally hide the preloader especially for IE
  setTimeout(function() {
    $(".pace-inactive").hide();
  }, 500);
});
